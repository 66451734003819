import React from 'react';
import { SelectableItems } from '../../utils/prescriptionSwitch';
import hairFall from '../../assets/medical/switch/hair-fall.png';
import sleep from '../../assets/medical/switch/sleep.png';
import ed from '../../assets/medical/switch/ed.png';
import ej from '../../assets/medical/switch/ej.png';
import be from '../../assets/medical/switch/rocket-line.png';
import { I18n } from 'react-redux-i18n';
import { Description } from '@material-ui/icons';

const PrescriptionChoice = ({ choosePrescriptionForm }) => {
  const prescriptionItems = [
    {
      id: SelectableItems.HAIR_LOSS.id,
      name: 'hair',
      soldOff: SelectableItems.HAIR_LOSS.soldOff,
      soldOffTitle: SelectableItems.HAIR_LOSS.title,
      image: hairFall,
      alt: 'Queda de cabelo',
      disabled: false
    },
    {
      id: SelectableItems.MELATONIN.id,
      name: 'sleep',
      soldOff: SelectableItems.MELATONIN.soldOff,
      soldOffTitle: SelectableItems.MELATONIN.title,
      image: sleep,
      alt: 'Saúde do sono',
      disabled: false
    },
    {
      id: SelectableItems.ED.id,
      name: 'ed',
      soldOff: SelectableItems.ED.soldOff,
      soldOffTitle: SelectableItems.ED.title,
      image: ed,
      alt: 'Saúde sexual',
      disabled: false
    },
    {
      id: SelectableItems.EJ.id,
      name: 'ej',
      soldOff: SelectableItems.EJ.soldOff,
      soldOffTitle: SelectableItems.EJ.title,
      image: ej,
      alt: 'Ejaculação precoce',
      disabled: false
    },
    {
      id: SelectableItems.BE.id,
      name: 'be',
      soldOff: SelectableItems.BE.soldOff,
      soldOffTitle: SelectableItems.BE.title,
      image: be,
      alt: 'Saúde sexual',
      description: 'Bem-estar',
      disabled: true
    },
  ];

  return (
    <section className="prescription-switch__container__step-one">
      <article className="prescription-switch__container__step-one__title">
        {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.TITLE')}
      </article>
      <article className="prescription-switch__container__step-one__subtitle">
        {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.DESCRIPTION')}
      </article>

      <article className="prescription-switch__container__step-one__switch-container">
        {prescriptionItems.map((item) => (
          <article
            key={item.id}
            className="prescription-switch__container__step-one__switch-container__switch"
          >
            <button
              className={`prescription-switch__container__step-one__switch-container__switch__item prescription-switch__container__step-one__switch-container__switch__item--${item.name}`}
              onClick={() =>
                choosePrescriptionForm(item.id, item.soldOff, item.soldOffTitle)
              }
              disabled={item.disabled}
            >
              <img
                src={item.image}
                className="prescription-switch__container__step-one__switch-container__switch__item__img"
                alt={item.alt}
              />
              <div>
                <h3 className="prescription-switch__container__step-one__switch-container__switch__item__text">
                  {item.soldOffTitle}
                </h3>
                {item.description && (
                  <h4 className="prescription-switch__container__step-one__switch-container__switch__item__text__subtitle">
                    {item.description}
                  </h4>
                )}
              </div>
            </button>
          </article>
        ))}
      </article>
    </section>
  );
};

export default PrescriptionChoice;